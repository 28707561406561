import React, {Component} from 'react';
import { Box } from "@chakra-ui/react";

class PostHome extends Component {

    render() {
        const image = this.props.post.featuredImage.node.localFile.childImageSharp.fluid;
        let title = this.props.post.title;
        let href = this.props.post.uri;
        let category = this.props.post.categories.nodes[0].name;
        var authors;
        if (this.props.post.autorxs && this.props.post.autorxs.authors){
            // https://stackoverflow.com/a/49289042
            authors = this.props.post.autorxs.authors.map(a => a.name).join(', ').replace(/, ([^,]*)$/, ' y $1')
        } else {
            authors = this.props.post.author.node.name;
        }

        let top = !this.props.bottom;
        let copete = this.props.post.copete.copete;

        return(
            <>
                <Box className={`post-home-container ${top ? 'top-post' : ''}`} 
                    padding={{base: "0px", md: top ? "0px" : "1.5vw"}}
                    width={{ base: "100%", md: "33.3%"}}>
                    <Box
                        className={"post-home"}
                        height={{ base: "100vw", md: "30vw" }}
                        backgroundImage={`url(${image.src})`}>
                        <span className={`copete`}>{copete}</span>
                        <a href={href} className={"post-title"}>{title}</a>
                        <div className={"post-home-authorname"}>Por {authors}</div>
                    </Box>
                    <div className={"post-home-category"}>{category}</div>
                </Box>
            </>
        );
    }

}

export default PostHome;
