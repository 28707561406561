import React, {Component} from 'react';
import Layout from '../layout';
import PostHome from '../post-home';
import SEO from '../seo';

class CategoryPage extends Component {

    posts = [];

    constructor(props) {
        super(props);
        this.setCategoryPosts();
    }

    setCategoryPosts = () => {
        this.posts = this.props.data.filter(p => {
            return p.categories.nodes.filter(category => category.slug === this.props.category).length > 0
        });
    }

    renderBottomPost = (post) => {
        return (<PostHome post={post} bottom={true}/>);
    }

    render() {
        return(
            <Layout>
                <SEO title={this.props.category_name + ` - Revista Oleada`}/>
                {this.posts.map(this.renderBottomPost)}
            </Layout>
        )
    }

}

export default CategoryPage;
